.edit-setting {

    .icon {
        span {
            cursor: pointer;

            .svg-icon {
                zoom: 1.5;
            }
        }

        .save-changes {
            .svg-icon {
                svg {}
            }
            .d-contents {
                display: contents;
            }
        }

        .cancel-changes {}
    }

    &.success {
        animation: bgFade 0.5s linear;
    }

    input {
        &.error {
            animation: shake 0.3s linear;
        }
    }
}

@keyframes bgFade {
    0% {
        background-color: rgba(80, 205, 137, 0.2);
    }

    0% {
        background-color: rgba(80, 205, 137, 0.2);
    }

    0% {
        background-color: rgba(80, 205, 137, 0.2);
    }

    0% {
        background-color: rgba(80, 205, 137, 0.2);
    }

    0% {
        background-color: rgba(80, 205, 137, 0.2);
    }

    0% {
        background-color: rgba(80, 205, 137, 0.2);
    }

    0% {
        background-color: rgba(80, 205, 137, 0.2);
    }

    0% {
        background-color: rgba(80, 205, 137, 0.2);
    }

    0% {
        background-color: rgba(80, 205, 137, 0.2);
    }

    0% {
        background-color: rgba(80, 205, 137, 0.2);
    }

    0% {
        background-color: rgba(80, 205, 137, 0.2);
    }

}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}