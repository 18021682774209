.react-select-pg {
  .pg-select__control {
    background-color: #f5f8fa;
    border-color: #f5f8fa;
  }
}
.custom-select {
  .pg-select__control {
    cursor: pointer !important;
    background-color: transparent !important;
    border: none !important;
    font-size: calc(1.26rem + 0.12vw) !important;
    font-weight: 600 !important;
  }
  .fa-caret-down {
    font-size: calc(1.26rem + 0.12vw) !important;
  }
}

/* Scroll Bar */
.pg-select__menu-list::-webkit-scrollbar {
  width: 8px !important;
}
.pg-select__menu-list::-webkit-scrollbar-thumb {
  background: lighten(#3b3b64, 15%) !important;
}
.pg-select__menu-list::-webkit-scrollbar-thumb:hover {
  background: lighten(#3b3b64, 6%) !important;
}

.pg-select__menu-list {
  scrollbar-color: lighten(#3b3b64, 6%) transparent !important;
  scrollbar-width: auto !important;
}
