.leaflet-container {
  .leaflet-tooltip {
    opacity: 1 !important;
    margin-left: 25px;
    padding: 1px 6px;
    font-weight: bold;
  }

  .leaflet-map-pane {
    .leaflet-pane {
      .leaflet-marker-icon {
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
      }
    }
  }
}

.leaflet-div-icon {
  border-radius: 100%;
  width: 15px !important;
  height: 15px !important;
  margin-left: -7.5px !important;
  margin-top: -7.5px !important;
}

.custom-container {
  .leaflet-tooltip-center,
  .leaflet-tooltip-top {
    font-size: 1.25rem !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  .leaflet-tooltip-top:before {
    display: none;
  }
  .leaflet-popup-content {
    min-width: 150px !important;
  }
  .shape-options-icon {
    font-size: 1.5rem;
    margin: 0 0.5rem;
  }
  .leaflet-popup-close-button {
    font-size: 2rem !important;
  }
  .shape-options-image {
    font-size: 1.5rem;
    margin: 0 10px 0 3px;
  }
  .leaflet-popup-close-button {
    display: none;
  }
}
