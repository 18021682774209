.dashboard-page {
    .map-block {
        position: relative;
        .map-loading-overlay {
            position: absolute;
            width: 100%;
            z-index: 2;
            background: rgba(0, 0, 0, 0.2);
            height: 100%;
        }

        .leaflet-container{
            .leaflet-control-container{
                position: absolute;
                right: 50px;
                top: 0;
            }
        }

        .live-tracking, .device-selection {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 500;
            background-color: #fff;
            margin-top: 10px;
            margin-left: 10px;
            border-radius: 4px;
            
            select{
                border: 1px solid #e7e7e7;
            }
        }

        .live-tracking {
            margin-top: 10rem !important;
            .mr-auto {
                margin-right: auto;
            }
        }
        
    }
}
.dashboard-cards{
    .card{
        .svg-icon{
            svg {
                path{
                    fill: #fff !important;
                }
            }
        }
    }
}
