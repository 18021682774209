.playlist {
  .info-message-icon {
    cursor: pointer;
    i {
      color: #10253e !important;
    }
  }
}

.bootup-badge {
  font-size: 10px;
  padding: 4px 6px;
  margin-left: 3px;
  border-radius: 5px;
}

.playlist-duration {
  padding: 8px 16px;
  margin: 0 8px;
}

.vacant-badge {
  background: rgb(34, 165, 34) !important;
}

.hired-badge {
  background: rgb(198, 34, 34) !important;
}
