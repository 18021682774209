@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

@media (max-width: 575.97px) {
  .modal-dialog-centered {
    margin: auto;
  }
  .mobile-content {
    display: block;
  }
  .tablet-content {
    display: none;
  }
}

.configuration-view {
  display: flex;
  flex-direction: column !important;
  font-family: 'Inter', sans-serif;

  .color-primary {
    color: #0c2f55;
  }

  .bg-primary {
    background: #0c2f55 !important;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;

    .back-button {
      background: none;
      border: none;
      font-size: 1.5em;
      cursor: pointer;
      color: #ffffff;
      font-weight: 500;
    }

    .logo {
      max-height: 32px;
    }
  }

  .footer {
    position: fixed;
    height: 50px;
    width: 100%;
    bottom: 0;
  }

  .content {
    padding: 10px;
    margin-bottom: 50px;
    margin-top: 60px;

    h1 {
      margin-bottom: 8px;
      font-size: 2rem;
    }

    .config-list {
      display: flex;
      flex-direction: column;

      .config-heading {
        margin-bottom: 2px;
        width: 100%;
        padding: 0 14px;
        padding-right: 20px;
        display: flex;
        color: #0c2f55;
        .first-message {
          width: 115px;
        }
      }

      .command-title {
        margin-left: 16px;
        word-wrap: break-word;
        width: calc(100% - 129px);
      }

      .config-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        background: #f9f9f9;
        padding: 18px 14px;
        border-radius: 8px;
        // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        border: 1px solid lightgray;

        .message-image {
          width: 115px;
          display: flex;
          // justify-content: center;
          img {
            height: 28px;
          }
          .msg-parent {
            max-width: 115px;
          }
          .img-expand {
            margin-left: 105px;
          }
          .img-dots {
            div {
              height: 28px;
              padding: 0;
            }
          }
        }

        div {
          h2 {
            margin: 0;
            font-size: 1.2em;
          }

          p {
            margin: 0;
          }
        }
      }
    }
  }

  @media (min-width: 576px) {
    .mobile-content {
      display: none;
    }
    .tablet-content {
      display: block;
    }

    .content {
      margin-top: 70px;
      font-size: 14px !important;

      h1 {
        font-size: 2.5rem !important;
      }
    }
    .config-list {
      flex-direction: row;
      flex-wrap: wrap;

      .config-heading {
        .first-message {
          width: 130px !important;
        }
      }

      .command-title {
        width: calc(100% - 308px) !important;
      }

      .config-item {
        width: 100%;
        padding: 20px 14px !important;
        padding-right: 20px !important;
        .message-image {
          width: 130px !important;
          display: flex;
          // justify-content: center;
          img {
            height: 32px !important;
          }
          .msg-parent {
            max-width: 130px !important;
          }
          .img-expand {
            margin-left: 120px !important;
          }
          .img-dots {
            div {
              height: 32px !important;
              padding: 0;
            }
          }
        }
      }

      .command-properties {
        margin-left: 16px;
        color: #0c2f55;
        column-gap: 1rem;
        display: flex;
        justify-content: end;
        width: 146px;
        .property {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .property-text {
          font-size: 12px;
        }
      }
    }
  }
}
