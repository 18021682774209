.simDataPage {
  .form-card {
    .search-sim {
      position: relative;
      background: #b8b8b8;
      padding: 20px 25px 40px 25px;
      border-radius: 5px;

      span.svg-icon {
        position: absolute;
        left: 30px;
      }

      input {
        margin-right: 20px;
        padding-left: 30px !important;
      }

      .fv-plugins-message-container {
        position: absolute;
      }
    }

    padding-bottom: 0px !important;
  }

  .simData {
    padding: 2.5rem;

    .smart-status {
      font-weight: 500;
    }

    .card-body {
      padding: 0px;
    }

    .status-box {
      padding-bottom: 10px;
    }
  }
}
