.bg-grey {
    background: #f5f8fa;
}

.content-controll {
    background-color: #f7f7f7;
    min-height: 20px;

    .col {
        border: 1px solid #c1c1c1;

        &:last-child {
            border-left: none;
        }

        &.animation-speed,
        &.animation-type {
            display: flex;
            align-items: center;

            span,
            small {
                margin-left: 4px;
            }
        }
    }
}

.list-content {
    min-width: 116px;
}

.drag-preview {
    display: inline-block;
    filter: opacity(0.3);

    .text-center {
        min-width: 120px;
    }
}

.message-config {
    max-width: 350px;

    .popover-header {
        font-size: 14px;
        font-weight: 600;
    }

    .popover-body {
        .btn-group {
            .btn {
                border: 1px solid #e4e6ef !important;
            }
        }

        .btn {
            padding: 6px 15px !important;
        }
    }
}