//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import "init";

@import 'animate.css';

// Components
@import "./core/components/components";
@import "components/components";

@import "react-toastify/dist/ReactToastify.css";

@import "leaflet/dist/leaflet.css";
@import "react-leaflet-markercluster/dist/styles.min.css"; // sass
@import "react-datepicker/dist/react-datepicker.css"; // sass

// Layout
@import "layout/layout";
@import "./core/layout/docs/layout";

@import "layout/theme";

