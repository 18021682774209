.page-title {
  font-size: 28px;
  color: #636363;
}

.bottom-logo {
  position: relative;

  div {
    text-align: center;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 30px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1550px !important;
  }
}

.react-datepicker__input-time-container {
  margin: 5px 45px 10px 15px !important;
}

.padding-69-75 {
  padding: 0.69rem 0.75rem !important;
}

.input-group-append {
  margin-left: 0 !important;
}

.modal {
  &.show {
    backdrop-filter: blur(1px);
    background: rgba(0, 0, 0, 0.3);
  }
}

@for $i from 1 through 100 {
  .w-#{$i}-pr {
    width: #{$i + '%'} !important;
  }
}

@for $i from 1 through 250 {
  .mr-#{$i} {
    margin-right: #{$i + 'px'} !important;
  }

  .ml-#{$i} {
    margin-left: #{$i + 'px'} !important;
  }
}

@for $i from 10 through 500 {
  .width-#{$i} {
    width: #{$i + 'px'} !important;
  }
}

code {
  box-shadow: none !important;
  background-color: transparent !important;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.states-row {
  padding: 4px 10px !important;
  margin-bottom: 4px !important;
  .form-select-sm {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
}

.category-icon-img {
  width: 85px;
  height: 50px;
  border-radius: 0.25rem;
}

.category-header-img {
  width: 255px;
  height: 50px;
}

.category-delete-table tr :first-child {
  padding-left: 10px !important;
}

.icon-image-preview {
  max-width: 265px !important;
  max-height: 170px !important;
}

.header-image-preview {
  max-width: 800px !important;
  max-height: 170px !important;
}

.campaign-header-image-preview {
  height: 380px !important;
  width: 100% !important;
}

.w-24px {
  width: 24px;
}

.w-20px {
  width: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-picker-time-panel-cell-inner {
  height: 32px !important;
  line-height: 32px !important;
  font-size: 16px !important;
}

.ant-picker-cell-inner {
  min-width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
  font-size: 16px !important;
}

.ant-picker-content th {
  font-size: 16px !important;
}

.ant-picker-now-btn {
  font-size: 16px !important;
}

.ant-btn.ant-btn-sm {
  font-size: 16px !important;
  height: 30px !important;
  width: 42px !important;
  padding: 0 0px !important;
  border-radius: 4px !important;
}

.object-fit-contain {
  object-fit: contain;
}

.company-logo {
  width: auto;
  height: 42px;
}

.duration-config {
  max-width: 100%;
}

.ant-color-picker {
  z-index: 9999;
}

.custom-color-picker,
.custom-color-picker-with-border {
  .ant-color-picker-color-block {
    height: inherit;
    width: inherit;
    border-radius: unset;
  }
}

.custom-color-picker-with-border {
  .ant-color-picker-color-block {
    .ant-color-picker-color-block-inner {
      border: 1px solid #4f4f4f !important;
    }
  }
}

.ant-color-picker {
  .color-selection {
    display: flex;
    align-items: center;

    .color-select {
      display: inline-block;
      width: 30px;
      height: 30px;
      border: 1px solid #4f4f4f;
      margin-right: 10px;
      cursor: pointer;

      &.active {
        border: 2px solid #4f4f4f;
      }
    }
  }
}

.min-height-5rem {
  min-height: 5rem;
}

.ant-picker-dropdown {
  z-index: 9999;
}

.width-max-content {
  width: max-content;
}

.border-spacing-8px {
  border-collapse: separate;
  border-spacing: 0 8px;
}

.white-space-nowrap {
  white-space: nowrap;
}

.margin-left-550px {
  margin-left: 550px;
}

.max-height-75vh {
  max-height: 75vh;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #ffffff;
}

.custom-tooltip {
  text-align: center;
  font-size: 0.925rem;
  padding: 0.75rem 1rem;
  max-width: 300px;
}

.max-width-300px {
  max-width: 300px;
}

.creative-preview {
  .modal-dialog {
    max-width: 80%;
  }
}

.custom-table {
  tr:first-child,
  th:first-child,
  td:first-child {
    padding-left: 0.75rem !important;
  }

  tr:last-child,
  th:last-child,
  td:last-child {
    padding-right: 0.75rem !important;
  }

  .odd-row-white {
    tr:nth-child(odd) {
      background-color: #ffffff;
    }
    tr:nth-child(even) {
      background-color: rgba(245, 248, 250, 0.75);
    }
  }
}

.track-device {
  .custom-date-picker {
    height: 38px;
  }
  .react-datepicker-popper {
    z-index: 2 !important;
  }
}

.bg-pointguard {
  background-color: #2082d6 !important;
}

.bg-stripe,
.bg-none {
  background-color: #b71717 !important;
}

.bg-fleet {
  background-color: #0091c8 !important;
}

.bg-free {
  background-color: #ff1555 !important;
}

.bg-pro {
  background-color: #0050a6 !important;
}

.bg-basic {
  background-color: #2a9e72 !important;
}

.bg-advertiser {
  background-color: #7239ea !important;
}

.bg-bootup {
  background-color: #5014d0 !important;
}

.text-underline {
  text-decoration: underline;
}

.text-underline:hover {
  text-decoration: underline !important;
}

.title-with-store {
  display: flex;
  align-items: center;

  .title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
  }
  .bootup-badge {
    width: fit-content;
  }
}

.bg-store {
  font-size: inherit !important;
  background-color: #183153 !important;
  padding: 1px 2px !important;
  display: flex;
  align-items: center;
  width: fit-content;

  img {
    margin: 0 !important;
    width: 16px !important;
    margin-left: 1px !important;
  }

  .store-text {
    margin: 0 4px;
    font-size: 10px;
  }
}

.svg-icon-command {
  svg [fill]:not(.permanent):not(g) {
    transition: unset;
    fill: revert-layer;
  }
}

.selected-card {
  border-color: #007bff;
  box-shadow: 0 0 10px rgba(0, 125, 255, 1.5) !important;
}

// .custom-store {
//   display: inline-block !important;
// }

.custom-legend {
  position: absolute;
  z-index: 400;
  right: 0;
  bottom: 0;
  margin: 1rem;
  background: white;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.legend-div {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
}

.legend-box {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 2px;
}

.custom-actions.dropdown-toggle::after {
  display: none;
}

.custom-actions.dropdown-toggle {
  padding: 0.5rem !important;
  font-size: 1.075rem;
  font-weight: 500;
}

.custom-action-menu.dropdown-menu {
  min-width: auto;
  .dropdown-item {
    font-size: 1.075rem;
    font-weight: 500;
  }
}

.compact-custom-actions.dropdown-toggle::after {
  display: none;
}

.compact-custom-actions.dropdown-toggle.btn-primary {
  background: transparent !important;
  padding: 0 !important;
}

.compact-custom-action {
  width: fit-content;
  padding: 0 !important;
  .btn.btn-primary:hover,
  .btn.btn-primary:focus,
  .btn.btn-primary:active {
    background-color: transparent !important;
  }
}

.preview-container {
  display: flex;
  align-items: center;
}

.custom-select-groups {
  font-size: 1.1rem;
  font-weight: 500;
  .pg-select__control {
    .pg-select__value-container {
      padding: 5.75px 8px;
    }
  }
  .pg-select__single-value {
    color: #a1a5b7;
  }
}

.badge-disabled {
  background-color: #a0a5b6;
}

.input-group:focus-within .input-group-text {
  border-color: #b5b5c3;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
}

$bg: $white;
$fill: $primary;

.hourglass {
  display: block;
  background: $bg;
  margin: 3em auto;
  width: 2em;
  height: 4em;
  animation: hourglass 1s linear infinite;
}

.outer {
  fill: $fill;
}

.middle {
  fill: $bg;
}

@keyframes hourglass {
  0% {
    transform: rotate(0deg);
    box-shadow: inset $fill 0 -0em 0 0, inset $bg 0 -2em 0 0, inset $fill 0 -4em 0 0;
  }
  80% {
    transform: rotate(0deg);
    box-shadow: inset $fill 0 -2em 0 0, inset $bg 0 -2em 0 0, inset $fill 0 -2em 0 0;
  }
  100% {
    transform: rotate(180deg);
    box-shadow: inset $fill 0 -2em 0 0, inset $bg 0 -2em 0 0, inset $fill 0 -2em 0 0;
  }
}

.schedule-table {
  table-layout: fixed;
}

.no-fill:hover {
  background-color: #f1faff;
  border-radius: 4px;
}

.device-track-popup {
  .leaflet-popup-content {
    margin: 15px 25px !important;
    width: auto !important;
    font-family: $font-family-sans-serif;
  }
}

.device-tabs {
  gap: 3rem;
  .nav-link {
    padding: 0;
    padding-bottom: 0.5rem !important;
    font-weight: 600;
    border: none;
    color: #a1a5b7;
  }
  .nav-link.active {
    padding-bottom: 1rem;
    color: #009ef7;
    border: none;
    border-bottom: 1px solid;
  }
}

.hover-effect {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hover-effect:hover {
  background-color: #f1faff;
}
