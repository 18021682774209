.input-number {
    position: relative;

    .control-icon {
        position: absolute;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 2px;
        top: 0;
        cursor: pointer;

        .svg-icon {
            svg {
                height: 40px;
                width: 40px;
            }
        }

        &.last {
            right: 0;
            margin-right: 2px;
        }
    }

    input {
        padding: 10px 45px;
        line-height: 0px;
    }
}