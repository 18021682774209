// .messageList {
//     .popover{
//         border: 1px solid #e4e6ef!important;
//         .bs-popover-top{
//             .popover-arrow{
//                 ::after{
//                     border-top-color: #e4e6ef !important;
//                 }
//             }
//         }
//     }
// }

.message-delete-table {
  tr {
    :first-child {
      padding-left: 10px !important;
    }
  }

  td {
    :first-child {
      padding-left: 0px !important;
    }
  }
}

.info-content {
  .react-json-view {
    word-break: break-all;
  }
}

// .outputs-table{
//     td{
//         :first-child{
//             padding-left: 0px !important;
//         }
//     }
// }

.outputs-card {
  min-height: 283px;
}

.outputs-table {
  .outputs-row {
    border-radius: 5px;
  }
}

.add-message {
  .content-card {
    .color-selection {
      display: flex;
      align-items: center;

      .color-select {
        display: inline-block;
        width: 30px;
        height: 30px;
        border: 1px solid #4f4f4f;
        margin-right: 10px;
        cursor: pointer;

        &.active {
          border: 2px solid #4f4f4f;
        }
      }

      .color-input {
        display: flex;
        align-items: center;
        padding-right: 10px;

        input {
          width: 47px;
          padding: 8px;
          margin-right: 10px;
        }

        label {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.add-message {
  .content-card {
    .dynamic-text {
      .btn-primary {
        padding: 5px !important;
        margin: 0px 5px;
      }

      .preview-text {
        background: transparent;
        color: inherit;
        border: none;
        padding: 0;
        padding-right: 5px;
        font-weight: inherit;
      }
    }
  }
}

.outputs-heading,
.intervals-heading {
  margin-left: 10px;
}

.outputs-heading,
.outputs-row {
  display: flex;
  gap: 35px;
  align-items: center;

  .opt-row:nth-child(1) {
    flex-basis: 30%;
  }

  .opt-row:nth-child(2) {
    flex-basis: 15%;
  }

  .opt-row:nth-child(3) {
    flex-basis: 30%;
  }

  .opt-row:nth-child(4) {
    text-align: center;
    // flex-basis: 30%;
  }
}

.intervals-heading,
.intervals-row {
  display: flex;
  gap: 35px;
  align-items: center;

  .interval-row:nth-child(1) {
    flex-basis: 50%;
  }

  .interval-row:nth-child(2) {
    flex-basis: 50%;
  }

  .interval-row:nth-child(3) {
    text-align: center;
    // flex-basis: 30%;
  }

  .input-group-text {
    padding: 0.75rem 0.75rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group-append {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.dynamic-parameters-row,
.dynamic-parameters-heading {
  display: flex;
  gap: 10px;
  align-items: center;

  :nth-child(1) {
    flex-basis: 40%;
  }

  :nth-child(2) {
    flex-basis: 40%;
  }

  :nth-child(3) {
    flex-basis: 20%;
  }
}

.dynamic-parameters-row,
.outputs-row,
.intervals-row {
  background-color: #f5f8fa;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}

.zone-align {
  display: flex;
  align-items: center;
  align-self: center;

  .nr-dimmed {
    opacity: 0.6;
  }
}

.info-message-icon {
  margin-left: 20px;
  cursor: pointer;

  i {
    color: #0f2744 !important;
    font-size: 25px !important;
  }
}

.info-content {
  p {
    font-size: 14px;
    word-break: break-all;
  }
}

.zone-position {
  .zone-box {
    display: flex;
    flex-direction: row;

    .box {
      height: 5px;
      width: 14px;
      background-color: #8f8f8f;
      margin-right: 4px;
      margin-bottom: 1px;
    }
  }

  .zone-align {
    .svg-icon {
      svg path {
        fill: #8f8f8f !important;
        opacity: 1 !important;
      }
    }
  }
}

.company-zone {
  .company-settings {
    .card {
      margin-top: 10px;
      background: #f5f8fa;

      &.no-bg {
        background: #fff;
      }

      .card-body {
        display: flex;
        flex-direction: row;
        padding: 10px 10px 10px 12px;
        align-items: center;

        .form-select {
          height: 35px;
          font-size: 14px;
          padding: 5px 15px;
          min-width: 100px;
        }

        .form-label {
          margin: 0px;
        }
      }
    }
  }
}

a {
  [disabled] {
    pointer-events: none;
  }
}

.message-preview {
  .modal-dialog {
    max-width: fit-content;
  }
}

.image-preview {
  display: flex;

  .msg-parent {
    max-width: 360px;
    overflow: hidden;
    display: flex;
    position: relative;

    .img-expand {
      position: absolute;
      background: #009ef7;
      height: 100%;
      width: 10px;
      top: 0;
      margin-left: 350px;
      cursor: pointer;

      .img-dots {
        div {
          height: 48px;
          width: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          padding-top: 10px;
          padding-bottom: 10px;

          span {
            background-color: #fff;
            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
        }
      }
    }

    &.show-full {
      max-width: max-content !important;
      overflow: visible;

      .img-expand {
        right: 0;
        margin-left: 0px !important;
        margin-right: -10px;
      }
    }
  }
}

.min-width-80px {
  min-width: 80px;
}

.images-preview {
  display: flex;
  border: 0;
  .msgs-parent {
    max-width: 100%;
    overflow: hidden;
    display: flex;
    position: relative;

    .imgs-expand {
      position: absolute;
      background: white;
      height: 30px;
      width: 24px;
      margin-left: 216px;
      cursor: pointer;

      .img-dots {
        div {
          height: 30px;
          width: 24px;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          padding-top: 10px;
          padding-bottom: 10px;

          span {
            background-color: #000000;
            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  &.show-full {
    max-width: max-content !important;
    overflow: visible;

    .img-expand {
      right: 0;
      margin-left: 0px !important;
      margin-right: -10px;
    }
  }
}
