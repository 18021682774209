.device-header {
  input {
    width: 100%;
    outline: none;
    margin-right: 10px;
    &:focus {
      outline: none;
    }
  }
}

.device-info-card {
  background-color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;

  .card-title {
    text-transform: uppercase;
    font-size: 20px;
  }

  .card-body {
    padding: 0px;

    &.customer-and-device {
      .info {
        margin-right: 10px;
      }
    }

    .info {
      margin-top: 10px;

      label {
        font-weight: 500;
      }

      .form-select {
        font-size: 13px;
      }
    }
  }
}

.device-image {
  width: 100%;
}

.btn-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.selected-devices-table {
  th, td {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    // margin-top: var(--bs-gutter-y);
    margin: 0.25rem 0 !important;
  }
  tr:nth-child(even) {
    background-color: #fff;
  }
  .tr {
    margin: 0.25rem 0 !important;
  }
}
