table {
    &.dragable-table {
        border-collapse: separate;
        border-spacing: 0 15px;

        tbody {
            tr {
                background-color: rgba(245, 248, 250, 0.75);

                td {
                    .drag-controll {
                        padding-left: 10px;
                        display: flex;

                        i {
                            &.bi-grip-horizontal {
                                font-size: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
}