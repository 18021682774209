$baseColor: rgb(19, 52, 49);
$baseGrediantColor: rgba(36, 159, 148, 1);
$basePrimary: rgb(47, 139, 132);

body {
  background: #f1f5f8;

  .auth-bg {
    background: url(/media/bg/auth_bg.png),
      linear-gradient(180deg, #012d5c 0%, #000000 35%, #013d7c 100%);
  }

  .store-bg {
    background: url(/media/bg/auth_bg.png) no-repeat fixed center;
  }
}

.aside {
  &.aside-dark {
    background: url(/media/bg/aside.png),
      linear-gradient(180deg, #012d5c 0%, #000000 35%, #013d7c 100%);
    background-color: transparent;
    background-size: cover;

    .aside-logo {
      background-color: transparent;
    }

    .menu {
      .menu-item {
        .menu-link {
          &.active {
            background-color: transparent;
          }
        }
      }
    }
  }
}

table.theme-table {
  tbody {
    tr {
      background-color: #fff;
      border-bottom: 1px solid #f5f8fa !important;
      border-top: none;

      td {
        padding: 10px !important;

        .sim-status {
          text-transform: capitalize;
        }

        a {
          text-decoration: underline;

          &.disabled {
            color: #dddddd;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  thead {
    tr {
      border-bottom: 1px solid #f5f8fa !important;
      border-top: none;
    }
  }
}

a {
  color: #7797fe;
}

.list-info-card {
  .input {
    height: 35px;
    padding: 8px;
    border: 1px solid #dddedf;
    border-radius: 5px;
  }
}

.flex-center-v {
  align-items: center;
}

.Toastify {
  &__toast {
    &--success {
      svg {
        fill: #47be7d;
      }
    }

    .Toastify__progress-bar {
      background: #47be7d;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
