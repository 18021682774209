@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

@media (max-width: 575.97px) {
  .modal-dialog-centered {
    margin: auto;
  }
}

.playlist-view {
  // padding-top: 55px;
  display: flex;
  flex-direction: column !important;
  font-family: 'Inter', sans-serif;

  .color-primary {
    color: #0c2f55;
  }

  .bg-primary {
    background: #0c2f55 !important;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;

    .back-button {
      background: none;
      border: none;
      font-size: 1.5em;
      cursor: pointer;
      color: #ffffff;
      font-weight: 500;
    }

    .logo {
      max-height: 32px;
    }
  }

  .footer {
    position: fixed;
    height: 50px;
    width: 100%;
    bottom: 0;
  }

  .content {
    padding: 12px;
    margin-bottom: 50px;
    margin-top: 60px;

    h1 {
      margin-bottom: 8px;
      font-size: 2rem;
    }

    .sub-heading {
      margin-bottom: 0.5rem;
      margin-left: 14px;
      font-size: 16px;
    }

    .playlist-list {
      display: flex;
      flex-direction: column;

      .playlist-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        background: #f9f9f9;
        padding: 14px;
        border-radius: 8px;
        // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        border: 1px solid lightgray;
        flex-direction: column;
        width: 100%;

        .message-image {
          width: calc(75% - 7px);
          display: flex;
          justify-content: left;
          img {
            height: 28px !important;
          }
          .msg-parent {
            max-width: 215px;
          }
          .img-expand {
            margin-left: 205px;
          }
          .img-dots {
            div {
              height: 28px;
              padding: 0;
            }
          }
        }

        .playlist-image {
          width: 100%;
          height: 40px;
        }

        .playlist-data {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          // justify-content: space-between;
        }

        .playlist-item {
          display: flex;
          justify-content: space-between;
          padding: 2px 0;
          flex-direction: column;

          .label {
          }

          .value {
            font-weight: bold;
          }

          .color-block {
            height: 18px;
            display: flex;
            align-items: center;
          }

          .color-palette {
            border: 1px solid;
            height: 12px;
            width: 72px;
          }
        }
      }
    }

    .playlist-item:nth-child(3),
    .playlist-item:nth-child(7) {
      width: 30%;
      margin-right: 3.33%;
    }

    .playlist-item:nth-child(1),
    .playlist-item:nth-child(5) {
      width: 15%;
      margin-right: 3.33%;
    }

    .playlist-item:nth-child(2),
    .playlist-item:nth-child(6) {
      width: 20%;
      margin-right: 3.33%;
    }

    .playlist-item:nth-child(4),
    .playlist-item:nth-child(8) {
      width: 25%;
    }

    .dynamic {
      display: flex;
      align-items: center;
      border: 1px solid #0c2f55;
      border-radius: 4px;
      color: #0c2f55;
      padding: 1px 5px;
      font-size: 10px;
      column-gap: 5px;
      height: 19px;
    }

    .dynamic-message {
      display: flex;
      margin-bottom: 0.5rem;
      width: 100%;
    }

    .playlist-count {
      font-size: 1.25rem;
      font-weight: 500;
    }
  }

  @media (min-width: 576px) {
    .content {
      margin-top: 70px;
      font-size: 14px !important;

      h1 {
        font-size: 2.5rem !important;
      }

      .sub-heading {
        font-size: 24px !important;
      }

      .playlist-count {
        font-size: 1.5rem;
        font-weight: 500;
      }

      .playlist-list {
        flex-direction: row;
        flex-wrap: wrap;

        .playlist-container {
          .message-image {
            width: 80%;
            display: flex;
            justify-content: left;
            img {
              height: 32px !important;
            }
            .msg-parent {
              max-width: 360px !important;
            }
            .img-expand {
              margin-left: 350px !important;
            }
            .img-dots {
              div {
                height: 32px !important;
                padding: 0;
              }
            }
          }

          .dynamic {
            // margin-left: 87px;
            margin-left: 63px;
          }
        }

        .playlist-item {
          flex-direction: row !important;
          justify-content: unset !important;
          align-items: center;

          .value {
            margin-left: 4px;
          }

          .color-block {
            height: 21px !important;
          }

          .color-palette {
            height: 15px !important;
            width: 55px !important;
          }
        }

        .playlist-item:nth-child(3),
        .playlist-item:nth-child(7) {
          width: 30%;
          margin-right: 3.33%;
        }

        .playlist-item:nth-child(1),
        .playlist-item:nth-child(5) {
          width: 20%;
          margin-right: 3.33%;
        }

        .playlist-item:nth-child(2),
        .playlist-item:nth-child(6) {
          width: 20%;
          margin-right: 3.33%;
        }

        .playlist-item:nth-child(4),
        .playlist-item:nth-child(8) {
          width: 20%;
        }
      }
    }
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    .content {
      font-size: 12px !important;

      .message-image {
        width: 75% !important;
      }

      .dynamic {
        margin-left: 51px !important;
      }
    }

    .playlist-item:nth-child(3),
    .playlist-item:nth-child(7) {
      width: 29% !important;
      margin-right: 2% !important;
    }

    .playlist-item:nth-child(1),
    .playlist-item:nth-child(5) {
      width: 20%;
      margin-right: 2% !important;
    }

    .playlist-item:nth-child(2),
    .playlist-item:nth-child(6) {
      width: 20%;
      margin-right: 2% !important;
    }

    .playlist-item:nth-child(4),
    .playlist-item:nth-child(8) {
      width: 24% !important;
    }
  }
}
