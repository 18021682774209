.react-datepicker {

    zoom: 1.3;

    .react-datepicker__navigation--previous {
        left: -2px;
    }

    .react-datepicker__navigation--next {
        right: -2px;
    }

    .react-datepicker__navigation-icon {
        &::before {
            border-width: 1.5px 1.5px 0 0;
            height: 7px;
            top: 10px;
            width: 7px;
        }
    }

    .react-datepicker__month-container {
        .react-datepicker__header {
            border-bottom: none;
            background-color: #fff;

            .react-datepicker__day-names {
                .react-datepicker__day-name {
                    font-weight: 600;
                }
            }

            .react-datepicker__current-month {
                font-weight: normal;
            }
        }
    }
}

input[type="time"]::-webkit-input-placeholder {
    visibility: hidden !important;
}

.react-datepicker__input-time-container {
    margin: 10px 45px 10px 15px !important;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
    line-height: 1 !important;
 }
 
 .react-datepicker__input-time-container {
    font-size: 1rem;
 }