@import url('https://cdn.datatables.net/1.13.5/css/jquery.dataTables.min.css');

.pagination {
  .page-item {
    display: flex;
    align-self: center;
  }
}

.table {
  thead {
    tr {
      th {
        .sortable {
          cursor: pointer;
        }
      }
    }
  }
}

.tableFixHead {
  max-height: 50vh;
  thead {
    position: sticky;
    top: 0;
    background: #f5f8fa;
  }
}
